"use client";

import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
} from "react";
import { Thread, Turn, Citation, Attribution } from "@/types/Thread";
import {
  sortAndCalculatePublisherAttributions,
  PublisherAttribution,
  sortedDocumentAttributions,
  DocumentAttribution,
} from "@/utils/attributionUtils";
import { apiClient } from "@/utils/apiClient";
import { decodeHtml } from "@/utils/decodeHtml";

interface ChatContextType {
  thread: Thread | null;
  createThread: (thread: Thread) => void;
  resetThread: () => void;
  loadThread: (threadId: string) => Promise<void>;
  addTurn: (turn: Turn) => void;
  getCurrentTurn: () => Turn | null;
  updateTurnResponse: (turnId: string, response: string) => void;
  updateTurnCitations: (
    turnId: string,
    citations: Record<string, Citation>
  ) => void;
  updateTurnAttributions: (
    turnId: string,
    attributions: {
      document_credit_dist: Record<string, number>;
      publisher_credit_dist: Record<string, number>;
    }
  ) => void;
  updateTurnRelatedQuestions: (
    turnId: string,
    relatedQuestions: string[]
  ) => void;
  getSortedPublisherAttributions: (turnId: string) => PublisherAttribution[];
  getSortedDocumentAttributions: (turnId: string) => DocumentAttribution[];
  getAllTurns: () => Turn[];
  updateTurnResponseTime: (turnId: string, responseTime: number) => void;
  fetchCitations: (threadId: string, turnId: string) => Promise<void>;
  fetchAttributions: (threadId: string, turnId: string) => Promise<void>;
  fetchRelatedQuestions: (turnId: string, response: string) => Promise<void>;
  citationsLoading: Record<string, boolean>;
  attributionsLoading: Record<string, boolean>;
  relatedQuestionsLoading: Record<string, boolean>;
  isProcessingRelatedQuestion: boolean;
  setIsProcessingRelatedQuestion: (value: boolean) => void;
  activeRelatedQuestionsTurnId: string | null;
  setActiveRelatedQuestionsTurnId: (turnId: string | null) => void;
}

const ChatContext = createContext<ChatContextType | undefined>(undefined);

export function ChatProvider({ children }: { children: ReactNode }) {
  const [thread, setThread] = useState<Thread | null>(null);
  const [citationsLoading, setCitationsLoading] = useState<
    Record<string, boolean>
  >({});
  const [attributionsLoading, setAttributionsLoading] = useState<
    Record<string, boolean>
  >({});
  const [relatedQuestionsLoading, setRelatedQuestionsLoading] = useState<
    Record<string, boolean>
  >({});
  const [isProcessingRelatedQuestion, setIsProcessingRelatedQuestion] =
    useState(false);
  const [activeRelatedQuestionsTurnId, setActiveRelatedQuestionsTurnId] =
    useState<string | null>(null);

  const createThread = (newThread: Thread) => {
    setThread(newThread);
    if (newThread.turns.length > 0) {
      setActiveRelatedQuestionsTurnId(newThread.turns[0].turnId);
    }
  };

  const resetThread = () => {
    setThread(null);
  };

  const addTurn = (newTurn: Turn) => {
    setThread((prevThread) => {
      if (!prevThread) return null;
      const updatedThread = {
        ...prevThread,
        turns: [...prevThread.turns, newTurn],
        lastInteraction: Date.now(),
      };
      setActiveRelatedQuestionsTurnId(newTurn.turnId);
      return updatedThread;
    });
  };

  const getCurrentTurn = (): Turn | null => {
    return thread?.turns[thread.turns.length - 1] || null;
  };

  const updateTurnResponse = (turnId: string, response: string) => {
    setThread((prevThread) => {
      if (!prevThread) return null;
      const updatedThread = {
        ...prevThread,
        turns: prevThread.turns.map((turn) =>
          turn.turnId === turnId ? { ...turn, response } : turn
        ),
      };
      console.log("Updated thread:", updatedThread);
      return updatedThread;
    });
  };

  const updateTurnCitations = (
    turnId: string,
    citations: Record<string, Citation>
  ) => {
    console.log("Updating citations for turn:", turnId, citations);
    setThread((prevThread) => {
      if (!prevThread) return null;
      const updatedThread = {
        ...prevThread,
        turns: prevThread.turns.map((turn) =>
          turn.turnId === turnId ? { ...turn, citations } : turn
        ),
      };
      console.log("Updated thread with citations:", updatedThread);
      return updatedThread;
    });
  };

  const updateTurnAttributions = (
    turnId: string,
    attributions: {
      document_credit_dist: Record<string, number>;
      publisher_credit_dist: Record<string, number>;
    }
  ) => {
    console.log("Updating turn attributions:", { turnId, attributions });
    setThread((prevThread) => {
      if (!prevThread) return null;
      const updatedThread = {
        ...prevThread,
        turns: prevThread.turns.map((turn) =>
          turn.turnId === turnId ? { ...turn, attributions } : turn
        ),
      };
      console.log("Updated thread with attributions:", updatedThread);
      return updatedThread;
    });
  };

  const updateTurnRelatedQuestions = useCallback(
    (turnId: string, relatedQuestions: string[]) => {
      setThread((prevThread) => {
        if (!prevThread) return null;
        return {
          ...prevThread,
          turns: prevThread.turns.map((turn) =>
            turn.turnId === turnId ? { ...turn, relatedQuestions } : turn
          ),
        };
      });
    },
    []
  );

  const getSortedPublisherAttributions = (
    turnId: string
  ): PublisherAttribution[] => {
    if (!thread) return [];
    const turn = thread.turns.find((t) => t.turnId === turnId);

    if (!turn || !turn.attributions) {
      return [];
    }

    return sortAndCalculatePublisherAttributions(turn.attributions);
  };

  const getSortedDocumentAttributions = (
    turnId: string
  ): DocumentAttribution[] => {
    if (!thread) return [];
    const turn = thread.turns.find((t) => t.turnId === turnId);

    if (!turn || !turn.attributions) {
      return [];
    }

    return sortedDocumentAttributions(turn.attributions);
  };

  const getAllTurns = (): Turn[] => {
    return thread?.turns || [];
  };

  const updateTurnResponseTime = (turnId: string, responseTime: number) => {
    setThread((prevThread) => {
      if (!prevThread) return null;
      return {
        ...prevThread,
        turns: prevThread.turns.map((turn) =>
          turn.turnId === turnId ? { ...turn, responseTime } : turn
        ),
      };
    });
  };

  const fetchCitations = useCallback(
    async (threadId: string, turnId: string) => {
      console.log(
        `fetchCitations called with threadId: ${threadId}, turnId: ${turnId}`
      );
      setCitationsLoading((prev) => ({ ...prev, [turnId]: true }));
      try {
        console.log(
          `Making request to citations API: /api/citations?threadId=${threadId}&turnId=${turnId}`
        );
        const data = await apiClient.get("/api/citations", {
          params: { threadId, turnId },
        });
        console.log("Citations API response:", data);

        // Decode HTML entities in citations before updating state
        const decodedData = Object.entries(data).reduce<
          Record<string, Citation>
        >((acc, [key, citation]) => {
          const typedCitation = citation as Citation;
          return {
            ...acc,
            [key]: {
              ...typedCitation,
              title: typedCitation.title ? decodeHtml(typedCitation.title) : "",
              first_words: typedCitation.first_words
                ? decodeHtml(typedCitation.first_words)
                : "",
            },
          };
        }, {});

        updateTurnCitations(turnId, decodedData);
      } catch (error) {
        console.error("Error fetching citations:", error);
      } finally {
        setCitationsLoading((prev) => ({ ...prev, [turnId]: false }));
      }
    },
    []
  );

  const fetchAttributions = useCallback(
    async (threadId: string, turnId: string) => {
      setAttributionsLoading((prev) => ({ ...prev, [turnId]: true }));
      try {
        const data = await apiClient.get("/api/attributions", {
          params: { threadId, turnId },
        });
        updateTurnAttributions(turnId, data);
      } catch (error) {
        console.error("Error fetching attributions:", error);
      } finally {
        setAttributionsLoading((prev) => ({ ...prev, [turnId]: false }));
      }
    },
    []
  );

  const fetchRelatedQuestions = useCallback(
    async (turnId: string, response: string) => {
      console.log("fetchRelatedQuestions called with turnId:", turnId);
      setRelatedQuestionsLoading((prev) => ({ ...prev, [turnId]: true }));

      const currentTurn = thread?.turns.find((t) => t.turnId === turnId);

      if (!currentTurn) {
        console.log("No turn found for turnId:", turnId);
        setRelatedQuestionsLoading((prev) => ({ ...prev, [turnId]: false }));
        return;
      }

      try {
        console.log("Making request to related-questions API");
        const data = await apiClient.post("/api/related-questions", {
          body: {
            question: currentTurn.userPrompt,
            response: response,
            previous_queries:
              thread?.turns
                .slice(
                  Math.max(0, thread.turns.indexOf(currentTurn) - 2),
                  thread.turns.indexOf(currentTurn)
                )
                .map((t) => t.userPrompt) ?? [],
          },
        });
        console.log("Related questions API response:", data);
        updateTurnRelatedQuestions(turnId, data.related_questions || []);
      } catch (error) {
        console.error("Error fetching related questions:", error);
      } finally {
        setRelatedQuestionsLoading((prev) => ({ ...prev, [turnId]: false }));
      }
    },
    [thread, updateTurnRelatedQuestions]
  );

  const loadThread = useCallback(
    async (threadId: string) => {
      if (!thread || thread.threadId !== threadId) {
        try {
          const data = await apiClient.get(`/api/threads/${threadId}`);
          if (!data) throw new Error("Failed to fetch thread data");

          const transformedThread = {
            threadId: data.thread_id,
            threadTitle: data.thread_title,
            lastInteraction: Date.now(),
            turns: data.turns.map((turn: any) => ({
              turnId: turn.turn_id,
              userPrompt: turn.user_prompt,
              response: turn.response,
              responseTime: turn.response_time,
              citations: turn.citations
                ? Object.entries(turn.citations).reduce<
                    Record<string, Citation>
                  >((acc, [key, citation]) => {
                    const typedCitation = citation as Citation;
                    return {
                      ...acc,
                      [key]: {
                        ...typedCitation,
                        title: typedCitation.title
                          ? decodeHtml(typedCitation.title)
                          : "",
                        first_words: typedCitation.first_words
                          ? decodeHtml(typedCitation.first_words)
                          : "",
                      },
                    };
                  }, {})
                : {},
              attributions: turn.attributions || [],
              relatedQuestions: turn.related_questions || [],
            })),
          };

          createThread(transformedThread);
          if (transformedThread.turns.length > 0) {
            setActiveRelatedQuestionsTurnId(
              transformedThread.turns[transformedThread.turns.length - 1].turnId
            );
          }
        } catch (err) {
          console.error("Error fetching thread data:", err);
        }
      }
    },
    [thread, createThread]
  );

  return (
    <ChatContext.Provider
      value={{
        thread,
        createThread,
        resetThread,
        addTurn,
        getCurrentTurn,
        updateTurnResponse,
        updateTurnCitations,
        updateTurnAttributions,
        updateTurnRelatedQuestions,
        getSortedPublisherAttributions,
        getSortedDocumentAttributions,
        getAllTurns,
        updateTurnResponseTime,
        fetchCitations,
        fetchAttributions,
        fetchRelatedQuestions,
        citationsLoading,
        attributionsLoading,
        relatedQuestionsLoading,
        isProcessingRelatedQuestion,
        setIsProcessingRelatedQuestion,
        activeRelatedQuestionsTurnId,
        setActiveRelatedQuestionsTurnId,
        loadThread,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
}

export function useChatContext() {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error("useChatContext must be used within a ChatProvider");
  }
  return context;
}

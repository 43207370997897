"use client";

import React, { useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Checkbox } from "@/components/ui/checkbox";
import { ChevronDown, ChevronUp, Loader2 } from "lucide-react";
import formatName from "../utils/formatName";
import { usePublishers } from "@/context/PublisherContext";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { cn } from "@/lib/utils";
import { Switch } from "@/components/ui/switch";

interface CheckboxWithIndeterminateProps
  extends React.ComponentProps<typeof CheckboxPrimitive.Root> {
  indeterminate?: boolean;
}

const CheckboxWithIndeterminate = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxWithIndeterminateProps
>((props, ref) => {
  const { indeterminate, ...checkboxProps } = props;

  return (
    <Checkbox
      ref={ref}
      {...checkboxProps}
      data-state={
        indeterminate
          ? "indeterminate"
          : checkboxProps.checked
          ? "checked"
          : "unchecked"
      }
    />
  );
});

CheckboxWithIndeterminate.displayName = "CheckboxWithIndeterminate";

interface SourceSelectionPopoverProps {
  className?: string;
  isDialog?: boolean;
}

export function SourceSelectionPopover({ className, isDialog }: SourceSelectionPopoverProps) {
  const {
    categories,
    publishers,
    selectedPublisherIds,
    togglePublisher,
    toggleCategoryPublishers,
    getSelectedCount,
    getCategorySelectedCount,
    isCategoryFullySelected,
    isCategoryPartiallySelected,
    selectAllPublishers,
    deselectAllPublishers,
    isLoading,
  } = usePublishers();

  const [open, setOpen] = useState(false);

  const handleWheel = (e: React.WheelEvent) => {
    e.stopPropagation();
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className="flex items-center gap-2 w-[148px] h-[39px] border border-black/10 rounded-full px-4"
        >
          <span className="text-black text-xs font-light font-inter">
            Select sources
          </span>
          <Badge
            variant="secondary"
            className="text-[#6e6d7a] text-xs font-normal"
          >
            {isLoading ? (
              <Loader2 className="h-4 w-4 animate-spin" />
            ) : (
              getSelectedCount()
            )}
          </Badge>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className={cn(
          // Default styles (>1054px)
          "w-[740px] mt-1 p-4 pb-2 absolute",
          "-translate-x-[169px]",
          // Tablet styles (780px-1054px)
          "[@media(max-width:1054px)]:w-[634px] [@media(max-width:1054px)]:-translate-x-[167px]",
          // Mobile styles (600px-780px)
          "[@media(max-width:780px)]:w-[486px] [@media(max-width:780px)]:-translate-x-[179px]",
          // Mobile styles (<600px)
          "[@media(max-width:600px)]:!w-[329px]",
          // Adjust translation for dialog vs normal search
          isDialog 
            ? "[@media(max-width:600px)]:!-translate-x-[190px]"  // Dialog translation
            : "[@media(max-width:600px)]:!-translate-x-[179px]", // Normal search translation
        )}
        align="end"
        alignOffset={0}
        side="bottom"
        sideOffset={8}
      >
        <div className="space-y-0">
          {/* Category Headers */}
          <div
            className={cn(
              // Base styles (>1054px) - add centering by default
              "grid grid-cols-5 gap-4 mb-2",
              "[@media(max-width:1054px)]:gap-2",
              "[@media(max-width:780px)]:gap-1 [@media(max-width:780px)]:px-1"
            )}
          >
            {categories.map((category) => {
              const categoryHasPublishers = publishers.some((pub) =>
                pub.categoryIds.includes(category.id)
              );

              return (
                <div
                  key={category.id}
                  className={cn(
                    // Base styles
                    "w-[125px] flex items-center justify-center gap-1",
                    // Tablet styles
                    "[@media(max-width:1054px)]:w-[107.6px]",
                    // Mobile styles
                    "[@media(max-width:780px)]:w-[87.6px]",
                    "[@media(max-width:600px)]:!w-[56.2px]"
                  )}
                >
                  <div
                    className={cn(
                      "flex items-center gap-1 w-fit",
                      // Stack vertically on mobile
                      "[@media(max-width:600px)]:flex-col [@media(max-width:600px)]:items-center"
                    )}
                  >
                    <CheckboxWithIndeterminate
                      id={category.id}
                      checked={isCategoryFullySelected(category.id)}
                      indeterminate={isCategoryPartiallySelected(category.id)}
                      onCheckedChange={() =>
                        toggleCategoryPublishers(category.id)
                      }
                      disabled={!categoryHasPublishers}
                      className={cn(
                        !categoryHasPublishers
                          ? "opacity-50 cursor-not-allowed"
                          : "",
                        "[@media(max-width:600px)]:mb-1" // Add spacing between checkbox and label
                      )}
                    />
                    <label
                      htmlFor={category.id}
                      className={cn(
                        "text-black text-xs font-light leading-none flex items-center gap-2",
                        "[@media(max-width:1054px)]:gap-1",
                        "[@media(max-width:780px)]:gap-[2px]",
                        // Stack label and badge vertically on mobile
                        "[@media(max-width:600px)]:flex-col [@media(max-width:600px)]:items-center [@media(max-width:600px)]:gap-1",
                        !categoryHasPublishers && "opacity-50"
                      )}
                    >
                      {category.name}
                      <Badge
                        variant="secondary"
                        className={cn(
                          "px-0.1",
                          "text-[#6e6d7a] text-[10px] font-normal",
                          !categoryHasPublishers && "opacity-50"
                        )}
                      >
                        {getCategorySelectedCount(category.id)}
                      </Badge>
                    </label>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Publishers Grid */}
          <div
            className={cn(
              "h-[180px] overflow-y-auto scrollbar-hide",
              "[@media(max-width:1054px)]:h-[367px]",
              "[@media(max-width:780px)]:h-[344px]"
            )}
            onWheel={handleWheel}
          >
            <div
              className={cn(
                "grid grid-cols-5 gap-4 py-2 px-1 bg-[#f6f6f6]",
                "[@media(max-width:1054px)]:gap-2",
                "[@media(max-width:780px)]:gap-1",
                "[@media(max-width:600px)]:gap-x-0 [@media(max-width:600px)]:gap-y-2"
              )}
            >
              {categories.map((category) => (
                <div key={category.id} className="space-y-2 [@media(max-width:600px)]:space-y-[2px]">
                  {publishers
                    .filter((pub) => pub.categoryIds.includes(category.id))
                    .sort((a, b) => {
                      if (category.name === "Books") {
                        const aLastName = a.name.split(" ").slice(-1)[0];
                        const bLastName = b.name.split(" ").slice(-1)[0];
                        return aLastName.localeCompare(bLastName);
                      }
                      return a.name.localeCompare(b.name);
                    })
                    .map((publisher) => (
                      <div
                        key={publisher.id}
                        onClick={() => togglePublisher(publisher.id)}
                        className={cn(
                          // Default styles
                          "w-[125px] h-[50px] flex items-center gap-2 p-2 rounded-lg cursor-pointer transition-all",
                          "bg-white hover:bg-gray-50",
                          // Tablet styles
                          "[@media(max-width:1054px)]:w-[107.6px]",
                          // Mobile styles
                          "[@media(max-width:780px)]:w-[87.6px]",
                          // Force exact mobile dimensions with !important
                          "[@media(max-width:600px)]:!w-[56.2px] [@media(max-width:600px)]:!h-[50px] [@media(max-width:600px)]:!p-1",
                          selectedPublisherIds.includes(publisher.id)
                            ? "border border-[#592cff]"
                            : "border border-[#d9d9d9]"
                        )}
                      >
                        <div
                          className={cn(
                            "flex items-center gap-1.5 min-w-0 w-full overflow-hidden",
                            // Adjust mobile layout
                            "[@media(max-width:600px)]:flex-col [@media(max-width:600px)]:items-center [@media(max-width:600px)]:gap-[2px]"
                          )}
                        >
                          <Avatar
                            className={cn(
                              "h-6 w-6 border border-gray-200 shrink-0",
                              "[@media(max-width:1054px)]:h-4 [@media(max-width:1054px)]:w-4",
                              // Adjust avatar size for mobile
                              "[@media(max-width:600px)]:h-4 [@media(max-width:600px)]:w-4"
                            )}
                          >
                            <AvatarImage
                              src={`/publishers/${publisher.image}-40x40@4x.png`}
                              alt={publisher.name}
                            />
                            <AvatarFallback
                              className={cn(
                                "text-xs",
                                "[@media(max-width:1054px)]:text-[10px]" // Smaller text for fallback
                              )}
                              delayMs={5000}
                            >
                              {publisher.name
                                .split(" ")
                                .map((word) => word[0])
                                .join("")
                                .slice(0, 2)
                                .toUpperCase()}
                            </AvatarFallback>
                          </Avatar>
                          <span
                            className={cn(
                              "text-[10px] font-medium leading-tight break-words w-full",
                              "line-clamp-2",
                              // Adjust text size and spacing for mobile
                              "[@media(max-width:600px)]:text-[8px] [@media(max-width:600px)]:line-clamp-2 [@media(max-width:600px)]:text-center"
                            )}
                          >
                            {publisher.name}
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
              ))}
            </div>
          </div>

          {/* Add Select All toggle at bottom */}
          <div className="flex justify-end items-center gap-2 pt-2 border-t">
            <span className="text-sm font-medium">Select all</span>
            <Switch
              checked={publishers.length === selectedPublisherIds.length}
              onCheckedChange={(checked) => {
                if (checked) {
                  selectAllPublishers();
                } else {
                  deselectAllPublishers();
                }
              }}
            />
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}

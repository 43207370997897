"use client";

import { useSession } from "next-auth/react";
import { ReactNode, useEffect } from "react";
import { useRouter, usePathname } from "next/navigation";
import { useToast } from "@/hooks/use-toast";
import Login from "./Login";
import { PublisherProvider } from "@/context/PublisherContext";
import { ChatProvider } from "@/context/ChatContext";
import { DialogSearchProvider } from "@/context/DialogSearchContext";
import SearchDialog from "@/components/SearchDialog";
import { Loader2 } from "lucide-react";

// Define paths that don't require authentication
const publicPaths = [
  "/invite",
  "/invite/error",
  "/invite/confirmation",
  "/invite/request",
];

interface AuthParams {
  error?: string;
  error_description?: string;
  callbackUrl?: string;
  returnUrl?: string;
}

export default function SessionGuard({ children }: { children: ReactNode }) {
  const { data: session, status } = useSession();
  const router = useRouter();
  const pathname = usePathname();
  const { toast } = useToast();

  // Check if current path is public
  const isPublicPath = publicPaths.some((path) => pathname?.startsWith(path));

  useEffect(() => {
    // Skip effect for public paths
    if (isPublicPath) return;

    // Handle session errors for non-public paths
    if (session?.error) {
      toast({
        title: "Session Error",
        description: "Your session has expired. Please sign in again.",
        variant: "destructive",
      });

      // Parse current URL to preserve existing params
      const currentUrl = new URL(window.location.href);
      const params: AuthParams = {
        error: session.error,
      };

      // Preserve existing error description if present
      if (currentUrl.searchParams.get("error_description")) {
        params.error_description =
          currentUrl.searchParams.get("error_description")!;
      }

      // Handle callback URL without nesting
      const existingCallback = currentUrl.searchParams.get("callbackUrl");
      if (existingCallback) {
        try {
          const callbackUrl = new URL(existingCallback, window.location.origin);
          // Only use the pathname to prevent nesting
          params.callbackUrl = callbackUrl.pathname;
        } catch {
          // If URL parsing fails, use the current pathname
          params.callbackUrl = pathname;
        }
      } else if (!pathname.startsWith("/auth/")) {
        // Only set callbackUrl for non-auth paths
        params.callbackUrl = pathname;
      }

      // Preserve returnUrl if present (for account creation flow)
      const returnUrl = currentUrl.searchParams.get("returnUrl");
      if (returnUrl) {
        params.returnUrl = returnUrl;
      }

      // Construct signin URL with preserved parameters
      const signInUrl = new URL("/auth/signin", window.location.origin);
      Object.entries(params).forEach(([key, value]) => {
        if (value) signInUrl.searchParams.set(key, value);
      });

      router.push(signInUrl.toString());
    }
  }, [session, router, toast, isPublicPath, pathname]);

  // Allow public paths to bypass authentication
  if (isPublicPath) {
    return <>{children}</>;
  }

  if (status === "loading") {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <div className="flex flex-col items-center gap-2">
          <Loader2 className="h-8 w-8 animate-spin" />
          <p className="text-sm text-muted-foreground">Loading...</p>
        </div>
      </div>
    );
  }

  // Handle unauthenticated state for protected routes
  if (status === "unauthenticated" || session?.error) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <Login />
      </div>
    );
  }

  // Wrap authenticated content with providers
  return (
    <PublisherProvider>
      <ChatProvider>
        <DialogSearchProvider>
          <SearchDialog />
          {children}
        </DialogSearchProvider>
      </ChatProvider>
    </PublisherProvider>
  );
}

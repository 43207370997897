"use client";

import React, { useEffect, useState } from "react";
import { cn } from "@/lib/utils";
import QuestionInput from "./QuestionInput";
import { SourceSelectionPopover } from "./SourceSelectionPopover";
import { usePublishers } from "@/context/PublisherContext";
import { useQuestionSubmit } from "@/hooks/useQuestionSubmit";
import { RecommendedQuestions } from "./RecommendedQuestions";
import { useRouter } from "next/navigation";
import { apiClient } from "@/utils/apiClient";

interface SearchCoreProps {
  className?: string;
  onSearchComplete?: () => void;
  isDialog?: boolean;
}

export function SearchCore({
  className,
  onSearchComplete,
  isDialog,
}: SearchCoreProps) {
  const router = useRouter();
  const { selectedPublisherIds, isLoading } = usePublishers();
  const { submitQuestion } = useQuestionSubmit();
  const [recommendedQuestions, setRecommendedQuestions] = useState<string[]>(
    []
  );
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(true);

  const noSourcesSelected = selectedPublisherIds.length === 0;

  useEffect(() => {
    const fetchRecommendedQuestions = async () => {
      try {
        const data = await apiClient.get("/api/recommended-questions");
        setRecommendedQuestions(data.questions);
      } catch (error) {
        console.error("Failed to fetch recommended questions:", error);
      } finally {
        setIsLoadingQuestions(false);
      }
    };

    fetchRecommendedQuestions();
  }, []); // Run once on mount

  const handleSubmit = async (question: string) => {
    try {
      // Add loading state if needed
      const response = await submitQuestion(
        question,
        undefined,
        selectedPublisherIds,
        0
      );

      if (response?.thread_id) {
        // Use router.push instead of redirect
        router.push(`/threads/${response.thread_id}`);
        onSearchComplete?.();
      }
    } catch (error) {
      console.error("Error submitting question:", error);
    }
  };

  const handleRecommendedQuestionClick = (question: string) => {
    handleSubmit(question);
  };

  return (
    <div
      className={cn(
        "bg-[#f3f3f3] rounded-[10px] backdrop-blur-[200px]",
        "p-5",
        "w-fit",
        "inline-flex flex-col",
        "max-w-[800px]",
        "[@media(max-width:1054px)]:max-w-[698px]",
        "[@media(max-width:780px)]:max-w-[568px]",
        !isDialog && "[@media(max-width:600px)]:min-h-screen",
        className
      )}
    >
      <div className="flex flex-col items-center w-full">
        <div
          className={cn(
            "text-[32px] font-semibold font-neue-haas text-center mb-4",
            "leading-[120%]",
            isDialog && "[@media(max-width:600px)]:mx-auto"
          )}
        >
          Let&rsquo;s get straight to the gist.
        </div>
        <div
          className={cn(
            "relative",
            "w-[760px] h-[146px]",
            "[@media(max-width:1054px)]:w-[658px]",
            isDialog
              ? "[@media(max-width:600px)]:!w-[308px] [@media(max-width:600px)]:mx-auto"
              : "[@media(max-width:600px)]:!w-[328px]",
            "[@media(max-width:780px)]:w-[486px]",
            "[@media(max-width:600px)]:!h-[148px]"
          )}
        >
          <QuestionInput
            variant="search"
            onSubmit={handleSubmit}
            disabled={isLoading || noSourcesSelected}
            placeholder={
              isLoading
                ? "Loading publishers..."
                : noSourcesSelected
                ? "Select sources to ask a question..."
                : "What would you like to know?"
            }
            rightElement={<SourceSelectionPopover isDialog={isDialog} />}
            noPublishersSelected={noSourcesSelected}
          />
        </div>
      </div>
      <div className={cn("flex flex-col items-center w-full", "gap-5")}>
        <div
          className={cn(
            "flex-1 flex [@media(min-width:601px)]:items-center justify-center",
            "[@media(min-width:601px)]:mt-4",
            "[@media(max-width:600px)]:mt-4",
            "[@media(max-width:600px)]:!rounded",
            isDialog && "[@media(max-width:600px)]:!mb-2",
            isDialog && "[@media(max-width:600px)]:!mt-0",
            isDialog && "[@media(max-width:780px)]:!mt-3",
            isDialog && "[@media(max-width:600px)]:hidden",
          )}
        >
          {!isLoadingQuestions && (
            <RecommendedQuestions
              questions={recommendedQuestions}
              onQuestionClick={handleRecommendedQuestionClick}
            />
          )}
        </div>
        <div>
          <div
            className={cn(
              "text-[10px] font-light font-Inter text-center text-black",
              isDialog && "[@media(max-width:600px)]:mt-3"
            )}
          >
            Gist.ai can make mistakes, so you should verify responses.
          </div>
        </div>
        <div
          className={cn(
            "text-[#241c15] text-xs font-normal leading-tight mt-[405px]",
            "[@media(max-width:780px)]:text-center [@media(max-width:780px)]:text-[10px] [@media(max-width:780px)]:px-0 [@media(min-width:601px)]:hidden", isDialog && "[@media(max-width:600px)]:hidden",
            "[@media(max-width:780px)]:w-[360px]",
            "[@media(max-width:600px)]:w-[328px]"
          )}
        >
          We&apos;re partnering with 1,000s of top publications, authors,
          and other content creators to ensure our answers are informed by
          trustworthy sources.
        </div>
      </div>
    </div>
  );
}

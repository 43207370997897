"use client";

import { signIn, signOut } from "next-auth/react";
import { Button } from "@/components/ui/button";
import { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import Image from "next/image";
import { Loader2 } from "lucide-react";
import dynamic from "next/dynamic";

const PrivacyTermsPopover = dynamic(() => import("./PrivacyTermsPopover"), {
  ssr: false,
});

export default function Login({ callbackUrl }: { callbackUrl?: string }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isPrivacyLoading, setIsPrivacyLoading] = useState(true);
  const [isTermsLoading, setIsTermsLoading] = useState(true);

  const handleLogin = async () => {
    try {
      setIsLoading(true);
      setError(null);

      const result = await signIn("keycloak", {
        callbackUrl: callbackUrl || "/",
        redirect: true,
      });

      if (result?.error) {
        setError("Failed to sign in. Please try again.");
      }
    } catch (err) {
      setError("An unexpected error occurred. Please try again.");
      console.error("Sign in error:", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const clearExpiredSession = async () => {
      const session = await fetch("/api/auth/session");
      const data = await session.json();
      if (data?.error === "RefreshTokenExpired") {
        await signOut({ redirect: false });
      }
    };
    clearExpiredSession();
  }, []);

  return (
    <Card className="sm:w-[480px] md:w-[572px] py-4 flex flex-col justify-between shadow-lg">
      <CardHeader className="pt-12">
        <div className="flex justify-center mb-12">
          <Image
            src="/logo-stacked.svg"
            alt="Gist Logo"
            width={85}
            height={118}
            className="w-auto h-auto"
            priority
          />
        </div>
        <CardDescription className="text-center text-[20px] text-[#241C15] font-semibold font-neue-haas">
          Search smarter, not harder.
        </CardDescription>
      </CardHeader>
      <CardContent className="flex flex-col items-center space-y-8">
        {error && <div className="text-sm text-red-500">{error}</div>}
        <Button
          onClick={handleLogin}
          className="w-48 bg-black text-white rounded-full"
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Connecting...
            </>
          ) : (
            "Log in"
          )}
        </Button>
      </CardContent>
      <CardFooter className="flex justify-center">
        <p className="mt-12 text-xs text-muted-foreground text-center max-w-sm">
          By signing in, you agree to our{" "}
          <PrivacyTermsPopover
            type="terms"
            isLoading={isTermsLoading}
            setIsLoading={setIsTermsLoading}
            onOpenChange={(open) => open && setIsTermsLoading(true)}
            targetClassName="text-gray-400 hover:text-gray-500"
          />{" "}
          and{" "}
          <PrivacyTermsPopover
            type="privacy"
            isLoading={isPrivacyLoading}
            setIsLoading={setIsPrivacyLoading}
            onOpenChange={(open) => open && setIsPrivacyLoading(true)}
            targetClassName="text-gray-400 hover:text-gray-500"
          />
        </p>
      </CardFooter>
    </Card>
  );
}

import { Button } from "./ui/button";
import { cn } from "@/lib/utils";

interface RecommendedQuestionsProps {
  questions: string[];
  onQuestionClick: (question: string) => void;
  className?: string;
  maxDisplay?: number;
}

interface RecommendedQuestionsProps {
  questions: string[];
  onQuestionClick: (question: string) => void;
  className?: string;
  maxDisplay?: number;
  variant?: 'nav' | 'search';
}

export function RecommendedQuestions({
  questions = [],
  onQuestionClick,
  className,
  maxDisplay,
  variant = 'search'
}: RecommendedQuestionsProps) {
  if (!questions?.length) return null;

  // Use maxDisplay prop or default based on variant
  const displayCount = maxDisplay ?? (variant === 'nav' ? 3 : 5);
  const displayQuestions = questions.slice(0, displayCount);

  return (
    <div className={cn(
      "flex gap-4 justify-center px-2 w-auto",
      // Mobile styles only for search variant
      variant === 'search' && [
        "[@media(max-width:780px)]:flex-wrap",
        "[@media(max-width:780px)]:gap-1",
        "[@media(max-width:780px)]:px-0",
        "[@media(max-width:780px)]:w-[415px]",
        "[@media(max-width:600px)]:!w-[328px]",
      ],
      className
    )}>
      {displayQuestions.map((question, index) => (
        <Button
          key={index}
          variant="secondary"
          className={cn(
            // Base styles
            "text-xs font-medium px-3 h-auto py-[6px] rounded-full bg-[#FFFFFF] hover:bg-white/75 text-[#616161] border border-gray-200 hover:border-gray-300 whitespace-normal text-left transition-all duration-300",
            // Mobile styles
            variant === 'search' && "[@media(max-width:780px)]:text-[10px] [@media(max-width:780px)]:px-2 [@media(max-width:780px)]:h-[30px] [@media(max-width:780px)]:flex [@media(max-width:780px)]:items-center",
            // Visibility rules
            variant === 'nav' && index >= 2 && "[@media(max-width:1054px)]:hidden",
            variant === 'search' && index >= 3 && "[@media(min-width:781px)]:hidden",
            "shrink-0 min-w-fit"
          )}
          onClick={() => onQuestionClick(question)}
        >
          {question}
        </Button>
      ))}
    </div>
  );
}

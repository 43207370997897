"use client";

import { useDialogSearch } from "@/context/DialogSearchContext";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogDescription,
  DialogTitle,
  DialogOverlay
} from "@/components/ui/dialog";
import { SearchCore } from "./SearchCore";
import { cn } from "@/lib/utils";

export default function SearchDialog() {
  const { isSearchOpen, closeSearch } = useDialogSearch();

  return (
    <Dialog open={isSearchOpen} onOpenChange={closeSearch}>
      <DialogOverlay className="fixed inset-0 z-[50] bg-black/50 backdrop-blur-sm" />
      <DialogContent className={cn(
        "fixed bg-[#f3f3f3] shadow-lg z-[50] rounded-[10px]",
        "p-0",
        // Desktop positioning
        "[@media(min-width:780px)]:left-[calc(50%+25px)] [@media(min-width:780px)]:top-[280px] [@media(min-width:780px)]:-translate-x-1/2",
        // Mobile positioning
        "[@media(max-width:780px)]:left-1/2 [@media(max-width:780px)]:right-1/2 [@media(max-width:780px)]:top-[280px]",
        // Max widths only, remove fixed heights
        "max-w-[800px]",
        "[@media(max-width:1054px)]:max-w-[698px]",
        "[@media(max-width:780px)]:max-w-[526px] [@media(max-width:600px)]:rounded-[10px]",
        "[@media(max-width:600px)]:!max-w-[348px] [@media(max-width:600px)]:rounded-[10px]",
        "[@media(max-width:780px)]:overflow-x-hidden"
      )}>
        <DialogHeader className="hidden">
          <DialogTitle></DialogTitle>
          <DialogDescription className="sr-only">
            Search dialog for asking questions
          </DialogDescription>
        </DialogHeader>
        <SearchCore isDialog onSearchComplete={closeSearch} />
      </DialogContent>
    </Dialog>
  );
}

import React, { useState, FormEvent, ChangeEvent } from "react";
import { ChevronDown } from "lucide-react";
import { cn } from "@/lib/utils";
import Image from "next/image";
interface QuestionInputProps {
  onSubmit: (question: string) => void;
  placeholder?: string;
  containerClassName?: string;
  textareaClassName?: string;
  disabled?: boolean;
  rightElement?: React.ReactNode;
  noPublishersSelected?: boolean;
  variant?: "search" | "followup";
}

const QuestionInput: React.FC<QuestionInputProps> = ({
  onSubmit,
  placeholder = "Ask anything...",
  containerClassName,
  textareaClassName,
  disabled = false,
  rightElement,
  noPublishersSelected = false,
  variant = "followup",
}) => {
  const [question, setQuestion] = useState<string>("");
  const [showShiftReturn, setShowShiftReturn] = useState<boolean>(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (question.trim()) {
      onSubmit(question.trim());
      setQuestion("");
      setShowShiftReturn(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (!disabled && question.trim() && !noPublishersSelected) {
        handleSubmit(e as unknown as FormEvent<HTMLFormElement>);
      }
    }
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setQuestion(e.target.value);
    // Kristen asked that we remove this from the UI. Will leave but just comment out here to deactivate.
    // setShowShiftReturn(e.target.value.trim() !== "");
  };

  return (
    <div
      className={cn(
        "relative",
        variant === "search" ? "h-full" : "",
        containerClassName
      )}
    >
      <form
        onSubmit={handleSubmit}
        className={cn(
          "w-full relative",
          variant === "search" ? "h-full" : "max-w-3xl mx-auto"
        )}
      >
        <div
          className={cn(
            "relative w-full h-full bg-white bg-gradient-to-r from-[#ffad00]/25 via-[#f05443]/25 to-[#8a80e4]/25",
            variant === "search"
              ? "p-[1px] rounded-[32px]"
              : "p-[6px] rounded-[36px]"
          )}
        >
          <div
            className={cn(
              "relative w-full bg-white rounded-[32px] flex",
              variant === "search"
                ? "h-full px-[25px] py-3 flex-col"
                : "h-16 px-[16px] py-3 items-center"
            )}
          >
            <textarea
              value={question}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              placeholder={placeholder}
              className={cn(
                "resize-none outline-none focus:outline-none",
                variant === "search"
                  ? "flex-1 w-full mb-1 pr-1"
                  : "flex-1 h-12 mr-1 pr-1",
                "disabled:bg-white disabled:opacity-100",
                "placeholder:text-[#616161] placeholder:font-light",
                textareaClassName
              )}
              rows={1}
              disabled={disabled}
              style={
                variant === "followup" ? { paddingTop: "12px" } : undefined
              }
            />
            <div
              className={cn(
                "flex items-center",
                variant === "search"
                  ? "w-full mt-auto justify-between"
                  : "h-full shrink-0"
              )}
            >
              {rightElement && variant === "followup" && (
                <>
                  <div className="h-full w-[1px] bg-black/20 mx-2" />
                  {rightElement}
                  <div className="h-full w-4" />
                </>
              )}
              {variant === "search" && rightElement}
              <button
                type="submit"
                disabled={disabled || !question.trim() || noPublishersSelected}
                className={cn(
                  "flex items-center justify-center w-10 h-10 rounded-full transition-opacity",
                  variant === "search" ? "ml-auto" : "",
                  "hover:opacity-80",
                  "disabled:cursor-not-allowed",
                  disabled || !question.trim() || noPublishersSelected
                    ? "bg-[#D9D9D9]"
                    : "bg-black"
                )}
              >
                <Image
                  src="/icons/Send.svg"
                  width={16}
                  height={16}
                  alt="Send"
                  priority
                />
              </button>
            </div>
          </div>
        </div>
      </form>
      <div
        className={cn(
          "h-4 mt-1 w-full",
          variant === "followup" ? "max-w-3xl" : ""
        )}
      >
        {showShiftReturn && (
          <div className="text-[9px] text-gray-500 text-right pr-1">
            Use{" "}
            <span className="font-medium text-[#241c15] rounded">
              shift+return
            </span>{" "}
            for new line
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionInput;
